<template>
  <article id="app_card">
    <div class="card">
      <main>
        <div class="icon-wrapper">
          <i class="rank">{{rank}}</i>
        </div>
        <a class="logo-link" :href="app.downloadUrl || app.serviceUrl">
          <img :src="app.logo" alt="로고">
        </a>
        <details>
          <summary class="info">
            <a :href="app.serviceUrl">{{app.title}}</a>
            <span class="company"><a :href="app.companyUrl || '#'">{{app.company}}</a></span>
            <span class="hash-tags">{{hashTagText}}</span>
            <span>별점 {{app.star}}</span>
          </summary>
        </details>
      </main>
      <div v-if="app.description" class="description">
        <hr>
        {{app.description}}
      </div>
    </div>
  </article>
</template>

<script>
  export default {
    props: {
      app: {
        type: Object,
        required: true
      },
      rank: {
        type: [Number, String],
        required: true
      }
    },
    components: {},
    data() {
      return {
      }
    },
    methods: {},
    computed: {
      hashTagText() {
        return this.app.hashTags.map((item) => '#' + item).join(" ");
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import '../stylesheets/common.scss';

  #app_card {
    display: block;
    position: relative;

    a {
      text-decoration: none;
    }
    .card {
      margin: 15px;

      main {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 8px;
        align-items: center;

        .icon-wrapper {
          position: absolute;
          top: -20px;
          left: -12px;
          color: rgba(74, 84, 82, 20);
          font-size: 2rem;
          font-weight: bold;
          border-radius: 50%;
          width: 30px;
        }

        .logo-link {
          align-self: center;

          img {
            margin: 8px;
            width: 80px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin: 8px;
          .company {
            font-size: 12px;
          }

          .hash-tags {
            color: gray;
          }
        }
      }

      .description {
        padding: 0 16px 16px 16px;

        hr {
          margin-bottom: 16px;
          margin-top: 0;
        }
      }
    }
  }
</style>