<template>
  <div id="apps">
    <h1>{{title}}</h1>
    <section class="col-sm-12" v-for="(app, rank) in apps">
      <app-card :app="app" :rank="rank + 1" />
    </section>
  </div>
</template>

<script>
  import AppCard from './app_card.vue';
  import GlobalNavHeader from "./global_nav_header.vue";

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      apps: {
        type: Array,
        required: true
      }
    },
    components: {GlobalNavHeader, AppCard},
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import '../stylesheets/common.scss';
  #apps {
    h1 {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin: 25px;
    }

    .dropdown button {
      margin: 25px auto;
      display: block;
    }
  }
</style>