<template>
  <div class="container">
    <h1>글자 수 세기</h1>
    <textarea v-model="text" placeholder="여기에 텍스트를 입력하세요"></textarea>
    <div class="stats">
      <p>글자 수: <strong>{{ text.length }}</strong></p>
      <p>공백을 제외한 글자 수: <strong>{{ charactersExcludingSpaces }}</strong></p>
      <p>바이트 수: <strong>{{ byteCount }}</strong></p>
      <p>단어 수: <strong>{{ wordCount }}</strong></p>
      <p>공백 수: <strong>{{ spaceCount }}</strong></p>
      <p>줄 수: <strong>{{ lineCount }}</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ''
    }
  },
  computed: {
    charactersExcludingSpaces() {
      return this.text.replace(/\s/g, '').length;
    },
    wordCount() {
      if (this.text.trim() === '') return 0;
      return this.text.trim().split(/\s+/).length;
    },
    spaceCount() {
      const spaces = this.text.match(/\s/g);
      return spaces ? spaces.length : 0;
    },
    lineCount() {
      if (this.text === '') return 0;
      return this.text.split(/\n/).length;
    },
    byteCount() {
      // 바이트 수 계산
      return new Blob([this.text]).size;
    }
  }
}
</script>

<style lang="scss" scoped>

@import '../stylesheets/common.scss';

$primary-color: #2c3e50;
$container-width: 600px;

#character_counter {

  .container {
    max-width: $container-width;
    margin: 0 auto;
    text-align: center;

    textarea {
      width: 100%;
      height: 150px;
      min-height: 250px;
      margin-bottom: 20px;
      padding: 10px;
      font-size: 16px;
      resize: vertical;
    }

    .stats {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 5px 0;
        font-size: 16px;

        strong {
          color: $primary-color;
        }
      }
    }
  }
}

</style>
