<template>
  <h1 v-text="getTitle()"></h1>
  <section class="col-sm-12" v-for="(app, rank) in apps">
    <app-card :app="app" :rank="rank + 1" />
  </section>
</template>

<script>
  import { defineComponent } from 'vue';
  import AppCard from './app_card.vue';

  export default defineComponent({
    name: 'ForGeneration',
    props: {
      apps: {
        type: Array,
        required: true
      },
      generation: {
        type: String,
        required: false,
      },
      headingTitle: {
        type: String,
        default: ''
      }
    },
    components: {AppCard},
    data() {
      return {
      }
    },
    methods: {
      getTitle() {
        if (this.headingTitle) {
          return this.headingTitle
        } else {
          return this.generation + "대가 많이 쓰는 소개팅 어플 순위"
        }
      },
    },
    mounted() {
    }
  });
</script>

<style lang="scss" scoped>
  @import '../stylesheets/common.scss';
  #for_generation {
  }
</style>