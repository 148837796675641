// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import common from './common.js';
import AppIndex from '../components/app_index.vue';
import Companies from '../components/companies.vue';
import ForGeneration from '../components/for_generation.vue';
import Apps from '../components/apps.vue';
import WeddingCompanies from '../components/wedding_companies.vue';
import RealEstateApps from '../components/real_estate_apps.vue';
import Everything30Dating from '../components/everything_30s_dating.vue';
import GlobalNavHeader from '../components/global_nav_header.vue';
import CharacterCounter from '../components/character_counter.vue';
import SchoolsMap from '../components/schools_map.vue';

import 'normalize.css/normalize.css';
import './application.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

window.common = common;
import axios from "axios";

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

common.setupAxios(axios);

document.addEventListener('DOMContentLoaded', () => {
    common.mountComponent('#app_index', AppIndex);
    common.mountComponent('#for_generation', ForGeneration);
    common.mountComponent('#companies', Companies);
    common.mountComponent('#apps', Apps);
    common.mountComponent('#wedding_companies', WeddingCompanies);
    common.mountComponent('#real_estate_apps', RealEstateApps);
    common.mountComponent('#everything_30s_dating', Everything30Dating);
    common.mountComponent('#global_nav_header', GlobalNavHeader);
    common.mountComponent('#character_counter', CharacterCounter);
    common.mountComponent('#schools_map', SchoolsMap);
});